.wl_header_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

.wl_title_ct {
    display: flex;
    align-items: center;
    gap: 5px;
}

.wl_record_container {
    display: flex;
    align-items: center;
    gap: 5px;
}

.wl_filter_container {
    display: flex;
    // gap: 5px;
    align-items: center;
}

.wl_filter_container_parent {
    display: flex;
    justify-content: space-between;
    padding-top: 7px;
}

.wl_sort_search_container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.wl_body_wraper {
    width: 100%;
    height: calc(100vh - 140px);
    background-color: #ffffff;
    margin-top: 10px;
    border-radius: 5px;
    overflow: scroll;
    padding: 20px;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

.wl_card_container {
    border: 1px solid #cdced9;
    border-radius: 5px;
    width: 320px;
    padding: 7px;
    height: 201px;
    position: relative;
}

.card_header_wraper {
    display: flex;
    gap: 3px;
    margin-bottom: 8px;
}

.wl-date-al {
    margin-top: 5px;
    font-size: 11px;
}

.wl-card-footer-wraper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}

.worked_count {
    text-align: end;
    margin-top: 3px;
}

.worked_count span {
    color: var(--text-color);
}

.wl_user_list_wraper {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: space-between;
    height: 20px;
}

.wl_user_list_wraper p:nth-child(1) {
    width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.wl_user_list_wraper p:nth-child(2) {
    width: 130px;
    color: #787878;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.wl_user_list_wraper p:nth-child(3) {
    width: 43px;
    color: #787878;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.wl_user_list_wraper p:nth-child(4) {
    width: 18px;
}

.wl_icon_wraper {
    display: none;

}

.wl_card_container:hover .wl_icon_wraper {
    display: flex;
    align-items: center;
    gap: 5px;
    position: absolute;
    right: 3%;
    background-color: #ffffff;
}

.wl_user_delete {
    display: none;
}

.wl_user_list_wraper:hover .wl_user_delete {
    display: inline-block;
}

.wl_name {
    white-space: nowrap;
    max-width: 245px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.customer-add {
    font-size: 11px !important;
}

.wl_active_card {
    box-shadow: 0px 8px 24px 0px rgb(149 157 165 / 38%);
    border: 1px solid #516beb;
}

// .wl_add_ic {
//     position: absolute;
//     right: 17%;
// }

// .wl_edit_ic {
//     position: absolute;
// }

// .wl_delete_ic {
//     position: absolute;
// }