.user-profile__input-conatiners {
  display: flex;
  justify-content: space-between;
  flex: 1;
  gap: 20px;
}

.user-profile__input-align {
  display: flex;
  // gap: 20px;
  // align-items: center;
  margin-bottom: 10px;
  flex-direction: column;
  flex: 1;
}

.user-profile__input-content {
  // border: 1px solid #cdced9;
  // box-shadow: 1px 2px 4px 0px #33368025;
  flex: 1;
  // padding: 15px;
  border-radius: 5px;
  height: calc(100% - 60px);
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.user-profile__input-align p {
  // white-space: nowrap;
  // width: 150px;
  text-align: left;
  color: #333860;
  // padding-bottom: 10px;
  // padding-left: 20px;
}

.user-profile__input-size {
  display: flex;
  align-items: center;
  gap: 5px;
}

.user-h {
  height: 95%;
}

.user-profile_bottom-section {
  display: flex;
  padding: 10px;
  justify-content: center;
  gap: 20px;
  // background-color: rgb(249, 249, 249);
  // border-top: 1px solid rgb(205, 206, 217);
}

.up-user__bio {
  display: flex;
  gap: 20px;
  align-items: center;
}

.up-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}
.user-triple_btn_invoice_status {
  position: absolute;
  // display: none;
  right: 0%;
}

.user-popover__btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 10px 20px;
}

.user-end_icon-wraper .user-triple_btn_invoice_status {
  display: block;
}
.sms-popover__btn {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.user-end_icon-wraper .user-triple_btn {
  display: none;
}

.table-main__body-row {
  position: relative;
}

.report_table-main__body-row {
  position: relative;
}

.user-triple_btn {
  position: absolute;
  display: none;
  right: 0%;
}

.report_dot {
  position: absolute;
  display: none;
  right: 0%;
}

.sms-gateway_btn {
  // display: none;
  transform: rotate(90deg);
  position: absolute;
  right: 13px;
  top: -9px;
}

.markdefault {
  display: none;
}

.addsmsgateway:hover .markdefault {
  display: block;
}

.table-main__body-row:hover .user-triple_btn {
  display: block;
  //   position: absolute;
}

.report_table-main__body-row:hover .user-triple_btn {
  display: block;
}

.user-triple_btn {
  padding: 15px 20px 10px 20px;
  cursor: pointer;

  &:hover {
    background-color: #f5f6fa;
    border-radius: 5px;
  }
}

.table-main__body-row:hover .report_dot {
  display: block;
  //   position: absolute;
}

.report_table-main__body-row:hover .report_dot {
  display: block;
}

.report_dot {
  // padding: 15px 20px 10px 20px;
  cursor: pointer;

  &:hover {
    background-color: #f5f6fa;
    border-radius: 5px;
  }
}

.invoice-status-maintain-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 6%;
  position: absolute;
  right: 0%;
}
// user-end_icon-wraper .user-end_icon-wraper label {
//   display: none;
// }
.user-end_icon-wraper label {
  display: none;
}

.table-main__body-row:hover .user-end_icon-wraper label {
  display: block;
}

.report_table-main__body-row:hover .user-end_icon-wraper label {
  display: block;
}

.user-end_icon-wraper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 6%;
  position: absolute;
  right: 0%;
}

.toggle-btn__relate {
  position: relative;
}

.--session-body {
  border-bottom: 0.5px solid #cdced9;
  border-left: 0.5px solid #cdced9;
  border-right: 0.5px solid #cdced9;
}

.--session-head {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.--session-cell {
  padding-left: 15px;
  justify-content: start;
}

.diable-sort .table-main__head-sort {
  display: none;
}

// .session_info_ali {
//   color: #ffffffff;
// }

.session_info_ali:nth-child(3) {
  display: none !important;
}

.--modifi-head-audit {
  flex: 1;
}

.--audit-modal-org {
  flex: 1;
  justify-content: start;
}

.--audit-content {
  height: auto !important;
}

.audit-table__body {
  height: 400px;
  overflow: scroll;
}

.cp__user__info__cinatiner {
  display: flex;
  height: 85%;
  gap: 15px;
  width: 50%;
  justify-content: center;
  margin: auto;
}

.pe__switch__btn {
  display: flex;
  gap: 10px;
  padding: 2px;
  border: 1px solid #cdced9;
  border-radius: 5px;
}

.pe__switch__btn p {
  padding: 5px;
  cursor: pointer;
}

.pe__input__wraper {
  display: flex;
  gap: 20px;
}
