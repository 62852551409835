.table-main {
  display: block;
  overflow-x: scroll;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
}

.table-main-container {
  width: 100%;
  display: table;
}

.table-main__head {
  display: table-caption;
  margin-bottom: 5px;
  position: sticky;
  top: 0;
}

.table-main__head-container {
  display: flex;
  flex-direction: column;
  background-color: var(--table-background-color);
  border-radius: 5px;
  border: 1px solid var(--border);

  overflow: hidden;
}
.report-table-main__head-row {
  display: flex;
  height: 50px;
  justify-content: space-evenly;
  align-items: center;
}

.table-main__head-container-tax {
  display: flex;
  flex-direction: column;
  background-color: var(--table-background-color);
  border-radius: 5px;
  // border: 1px solid var(--border);
  overflow: hidden;
}

.table-main__head-row {
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
}
.table-main__head-row-horizontal {
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  // border: 1px solid var(--border);
  border-bottom: none !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.table-main__head-row--req_modifier {
  display: flex;
  height: 50px;
  align-items: center;
}
.table-main__head-row_dashboard {
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
}

.table-main__head-row:nth-child(even) {
  background-color: var(--white);
}

.table-main1__head-cell-wrapper {
  height: 100%;
  display: flex;
  position: relative;
  min-width: 100px !important;
}
.table-main1_first__head-cell-wrapper {
  height: 100%;
  display: flex;
  position: relative;
  min-width: 40px !important;
}
.table-main__head-cell-wrapper {
  height: 100%;
  display: flex;
  position: relative;
}

.table-main__head-cell {
  display: flex;
  flex-direction: column;
  // margin: 5px;
  text-align: initial;
  // width: auto;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  user-select: none;
  position: static !important;
  // flex: 1;
  width: 100%;
}

.table-main__head-cell_content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.table-main__head-cell_content--left-align {
  justify-content: flex-start;
  padding-left: 5px;
}

.table-main__head-cell_content--right-align {
  justify-content: flex-end;
}

.table-main__head-sort {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.table-main__head-sort .active path {
  fill: var(--text-color);
}

.table-main__head-cell-resizer {
  width: 5px;
  height: 100%;
  position: absolute;
  right: 0px;
  overflow: hidden;
  border: 1px solid transparent;
  top: 0%;
  transition: 0.3s;

  .table-main__head-cell-wrapper:hover & {
    border-right: 0.3px solid var(--drag-drop-color);
    transition: 0.5ms;
  }
}

.table-main__head-cell-drag-n-drop {
  position: absolute;
  bottom: -2%;

  & svg ellipse {
    fill: var(--drag-drop-color);
    opacity: 0;

    .table-main__head-cell-wrapper:hover & {
      opacity: 1;
      transition: opacity 1s;
    }
  }
}

.table-main__head-title-container {
  display: flex;
  align-items: center;
  text-align: center;
}

.table-main__head-cell-container {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.table-main__body {
  display: table-row-group;
}

.table-main__body-row {
  display: table-row;
  margin-top: 7px;
  border: 1px solid var(--border);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  text-align: center;
  background-color: var(--white);
  height: 50px;
}

.report_table-main__body-row {
  display: table-row;
  margin-top: 7px;
  border: 1px solid var(--border);
  border-radius: 5px;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px;
  text-align: center;
  background-color: var(--white);
  height: 50px;
}
.--row_overflow {
  overflow: hidden;
}
.table-main__body-row.expanded {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.report_table-main__body-row.expanded {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.table-main__body-cell {
  // width: auto;
  display: flex;
  // justify-content: center;
  align-items: center;
  // width: 100%;
}
.table-main1__body-cell {
  // width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px !important;
  // width: 100%;
}
.table-main1_first__body-cell {
  // width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px !important;
  // width: 100%;
}
.table-main__row-expanded {
  background-color: var(--expand-row);
  border: 1px solid var(--border);
}

.table-main__row-expanded-bottom {
  background: var(--expand-row-bottom);
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--expand-text-color-bottom);
  font-size: 13px;
  font-weight: 700;
  border-bottom: 1px solid var(--border);
  border-left: 1px solid var(--border);
  border-right: 1px solid var(--border);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  gap: 20px;
}

.user-table__main {
  height: 95%;
}

.adhoc-table:nth-child(5) .table-main__head-cell div {
  text-align: center !important;
  justify-content: end;
}

.adhoc-table:nth-child(6) .table-main__head-cell div {
  text-align: center !important;
  justify-content: center;
}

.adhoc-table:nth-child(7) .table-main__head-cell div {
  text-align: center !important;
  justify-content: right;
}

.row__disable {
  background-color: #f3f3f3;
  border: 1px solid #cdced9;
  pointer-events: none;
}

.--cus__currency {
  gap: 0px !important;
}

.customer__tabel__body {
  display: block;
  overflow-x: scroll;
  overflow-y: scroll;
  height: calc(100vh - 245px);
}
.report_pie_bar_table {
  display: block;
  overflow-x: scroll;
  overflow-y: scroll;
  // height: calc(100vh - 450px);
}

.customer__tabel__body_expand {
  display: block;
  overflow-x: scroll;
  overflow-y: scroll;
  height: calc(100vh - 425px);
}

.--layout_modifier .customer__tabel__body {
  height: calc(100vh - 280px);
}

.--table__modifier {
  display: block;
  overflow-x: none;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
}

.co_table_head_cell_aligment {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.co_table_head_cell_aligment--left-align {
  justify-content: flex-start;
}

.co_table_head_cell_aligment--right-align {
  justify-content: flex-end;
}

.table-delete__body-row {
  display: table-row;
  margin-top: 7px;
  color: #cdced9;
  border: 1px solid #cdced9;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  text-align: center;
  background-color: #f8f8fb;
  height: 50px;
}

.table-delete__body-row p {
  color: #cdced9;
}

.co-payment-overview-table_body-group {
  display: flex;
  border-bottom: 1px solid #eeeeee;
  padding: 1px 5px;
  background-color: #ffffff;
  align-items: center;
}

//invoice Status Table

.table-invoice-status-main__body-row {
  display: table-row;
  margin-top: 7px;
  border: 1px solid var(--border);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  text-align: center;
  background-color: var(--white);
  min-height: 50px;
  max-height: 120px;
}

.table-invoice-status-main__body-row.expanded {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
