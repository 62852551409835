.header_card {
  display: flex;
  gap: 9px;
  padding: 13px;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid rgb(238, 238, 238);
}

.active {
  background-color: #f5f7fd;
  border-top: 1px solid rgb(238, 238, 238);
  border-radius: inherit;
}

.whatsapp_sub-text {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.notification_text-part {
  background-image: url(../../img/whatsappbg_img.png);
  height: calc(95vh - 140px);
  overflow: auto;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-bottom: 2px;
  padding-top: 12px;
}

.notification {
  height: auto;
  width: calc(100% - 200px);
  position: relative;
  margin-left: 2%;
  margin-top: 2%;
  border-radius: 5px 5px;
  padding: 3px 10px;
}

.---chat-box-sender {
  background-color: #fff;
}

.---chat-box-sender::before {
  border: 10px solid transparent;
  border-radius: 0.25rem;
  border-top: 15px solid #fff;
  content: "";
  height: 0;
  left: -10px;
  position: absolute;
  top: 0px;
  width: 0;
}

.whatsapp_msg .-notification {
  width: calc(100% - 189px);
  margin-left: 2%;
}

.--normal-msg {
  display: flex;
  justify-content: end;
}

.---chat-box-replay {
  background-color: #d9fdd3;
  margin-right: 2%;
}

.---chat-box-replay::before {
  border: 10px solid transparent;
  border-radius: 0.25rem;
  border-top: 15px solid #d9fdd3;
  content: "";
  height: 0;
  right: -10px;
  position: absolute;
  top: 0px;
  width: 0;
}

.timeline_chat_time_status {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
}

.chat_body_container {
  height: calc(95vh - 40px);
  background-image: url(../../img/whatsappbg_img.png);
  overflow: auto;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-bottom: 2px;
  padding-top: 12px;
  background-color: #ffffff;
}

.chat_notification {
  height: auto;
  width: calc(100% - 501px);
  position: relative;
  margin-left: 2%;
  margin-top: 2%;

  padding: 3px 10px;
}

.--chat_body {
  // background-color: #d9fdd3;
  margin-right: 2%;
  border: 1px solid #516beb;
  padding: 12px;
  border-radius: 5px 5px 5px 0px;
}

// .--chat_body::before {
//   border: 10px solid transparent;
//   border-radius: 0.25rem;
//   border-top: 15px solid #d9fdd3;
//   content: "";
//   height: 0;
//   right: -10px;
//   position: absolute;
//   top: 0px;
//   width: 0;
// }

.--chat_body_sender {
  background-color: #e2e1e1;
  padding: 12px;
  border-radius: 5px 5px 0px 5px;
  margin-right: 10px;
  // border: 1px solid #516beb;
}

.form_customer {
  margin-right: 13px;
  margin-top: 3px;
}

.form_zoya {
  margin-left: 17px;
  margin-top: 3px;
}

.chat_mark_as_read {
  position: absolute;
  display: none;
  top: 1%;
  right: 0
}

.chat_lst_date {
  position: absolute;
  bottom: -5%;
  right: 0;
}



.header_card:hover .chat_mark_as_read {
  display: inline-block;
}

.lst_msg_text {
  margin-top: 5px;
}

.header_card:hover {
  background-color: #eeeeee;
}

.marked_read {
  background-color: #fcfcfc;
}