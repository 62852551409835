.content-body--customer-overview {
  display: flex;
  grid-template-columns: repeat(4, 1fr);
  // grid-gap: 20px;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  // padding-bottom: 25px;
}

.role-text-disabled {
  color: #adadad !important;
}

.role-text-enabled {
  color: #333860 !important;
}

.combined-role-enabled {
  margin-top: 20px;
  display: flex;
  padding: 10px;
  border: 0.5px solid #cdced9;
  width: 575px;
  align-items: center;
  background-color: #f2f2f3;
  justify-content: space-between;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.combined-role-disabled {
  margin-top: 20px;
  display: flex;
  padding: 10px;
  border: 0.5px solid #cdced9;
  width: 575px;
  align-items: center;
  background-color: #cde7fd;
  justify-content: space-between;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.combined-role-enabled-bottom {
  border-top: 0px;
  display: flex !important;
  padding: 10px;
  border: 0.5px solid #cdced9;
  border-radius: 5px;
  width: 575px;
  // align-items: center;
  background-color: #f2f2f3;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  // flex-direction: column;
  gap: 20px;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
}

.combined-role-enabled-bottom-disabled {
  border-top: 0px;
  display: flex !important;
  padding: 10px;
  border: 0.5px solid #cdced9;
  border-radius: 5px;
  width: 575px;
  // align-items: center;
  background-color: #cde7fd;
  justify-content: space-between !important;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  // flex-direction: column;
  gap: 20px;
  flex-wrap: wrap;
  // justify-content: space-between;
}

.role-disabled {
  margin-top: 20px;
  display: flex;
  padding: 10px;
  border: 0.5px solid #cdced9;
  border-radius: 0.5px solid #cdced9;
  border-radius: 5px;
  width: 575px;
  align-items: center;
  background-color: #f2f2f3;
  justify-content: space-between;
}

.role-enabled {
  margin-top: 20px;
  display: flex;
  padding: 10px;
  border: 0.5px solid #cdced9;
  border-radius: 0.5px solid #cdced9;
  border-radius: 5px;
  width: 575px;
  align-items: center;
  background-color: #cde7fd;
  justify-content: space-between;
}

.org_name {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 171px;
}

.content-container-overview {
  display: flex;
  width: calc(100% - 60px);
  height: 100vh;
  flex-direction: column;
  padding: 25px 25px 0px 25px;
  background-color: #f0f3f8;
  // grid-row: 1/4;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 99.2%;
}

td,
th {
  // border: 1px solid #dddddd;
  text-align: left;
  // padding: 8px;
  height: 30px;
}

th {
  // border: 1px solid #dddddd;
  text-align: left;
  // padding: 8px;
  height: 30px;
}

// .card-wrapper {
// grid-column: 1/2;
// grid-row: 1/4;
// width: 100%;
// height: 80vh;
// }
.customer-profile-tab {
  display: flex;
  margin-top: 0px;
  overflow: hidden;
  gap: 10px;
  height: 100%;
}

.cp-tab__filters {
  display: flex;
  padding: 10px;
  justify-content: space-between;
}

.cp-tab__table-header-cell {
  display: flex;
  flex-direction: column;
  margin: 5px;
  text-align: justify;
  width: auto;
  justify-content: center;
  // align-items: center;
  overflow: hidden;
}

.cp-tab__table-header-cell-review-promise {
  display: flex;
  flex-direction: column;
  margin: 5px;
  text-align: justify;
  width: auto;
  justify-content: center;
  // align-items: center;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  color: "#333860";
}

.cp-tab__table-header-cell-payment {
  display: flex;
  flex-direction: column;
  margin: 5px;
  // text-align: justify;
  width: auto;
  justify-content: center;
  // align-items: center;
  overflow: hidden;
  font-size: 13px;
  font-weight: 700;
  color: "#333860";
}

.cp-tab__table-header-cell--select {
  display: flex;
  flex-direction: column;
  margin: 5px;
  text-align: justify;
  width: auto;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100px !important;
}

.cp-tab__table-pagination {
  background-color: #f9f9f9;
  padding: 0px 23px;
}

.cp-tab__tag-overdue {
  display: flex;
  align-items: center;
}

.cp-tab__tag-overdue-icn {
  margin-top: 2px;
}

.cp-tab__tag-overdue-content {
  padding-left: 5px;
}

.cp-tab__search-filter {
  display: flex;
}

.customer-profile-tab__table {
  /* margin-top: 40px; */
  display: block;
  position: relative;
  overflow-x: scroll;
  overflow-y: scroll;
  /* height: 458px; */
  /* height: 72%; */
  text-align: center;
}

.invoice__table--scroll {
  overflow-y: clip !important;
}

.cp-tab__table-wrapper {
  width: 100%;
  display: table;
}

.cp-tab__table-wrapper--credit {
  height: 100%;
  width: 100%;
  display: table;
}

.cp-tab__table-header {
  display: table-caption;
  text-align: center;
  font-size: 20px;
  /* margin-bottom: 5px; */
  /* margin-top: 10px; */
  position: sticky;
  top: 0;
  /* z-index: 9; */
}

.cp-tab__table-haeder-sort {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
}

.cp-tab__table-header-content {
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
}

.cp-tab__table-header-cell-content:hover .drag-drop svg ellipse {
  opacity: 1;
  transition: opacity 1s;
}

.cp-tab__table-header-cell-content {
  height: 100%;
  display: flex;
  position: relative;
}

.cp-tab__table-header-cell-content-payment {
  height: 100%;
  display: flex;
  position: relative;
  flex: 1;
}

.cp-tab__table-header-cell-revenue-billing-head {
  height: 100%;
  display: flex;
  position: relative;
}

.cp-tab__table-header-cell-dispute {
  height: 100%;
  display: flex;
  box-sizing: border-box;
  flex: 150 0 auto;
  min-width: 0px;
  width: 150px;
  position: relative;
}

.cp-tab__table-header-cell-dispute:nth-child(1) {
  position: relative;
  width: 0px;
  flex: 6%;
}

.cp-tab__table-header-cell-content:hover .btn-boter {
  border-right: 0.3px solid #d6d6d6;
  transition: 0.5ms;
}

.cp-tab__table-header-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #f5f6fa;
  font-size: 20px;
  border: 1px solid #cdced9;
}

.cp-tab__table-header-wrapper-dispute_table {
  display: flex;
  flex-direction: column;
  background-color: #f5f6fa;
  font-size: 20px;
  border: 1px solid #cdced9;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.cp-tab__table-header-wrapper--credit {
  display: flex;
  flex-direction: column;
  font-size: 20px;
}

.cp-tab__table-body-content {
  gap: 10px;
}

.cp-tab__table-body-content {
  display: table-row;
  /* margin-top: 82px; */
  border-bottom: 0.5px solid #cdced9;
  /* border-radius: 5px; */
  justify-content: center;
  align-items: center;
  padding: 5px;
  text-align: center;
  background-color: #fff;
  height: 50px;
  // overflow: hidden;
  position: relative;
}

.cp-tab__table-body-content-dispute {
  display: table-row;
  /* margin-top: 82px; */
  border-bottom: 0.5px solid #cdced9;
  border-left: 0.5px solid #cdced9;
  border-right: 0.5px solid #cdced9;

  /* border-radius: 5px; */
  justify-content: center;
  align-items: center;
  padding: 5px;
  text-align: center;
  background-color: #fff;
  height: 60px;
  position: relative;
  /* overflow: hidden; */
}

.cp-tab__table-body-content-dispute__remove {
  position: absolute;
  right: 5px;
  background: #ffffff;
  visibility: hidden;
  cursor: pointer;
}

.cp-tab__table-body-content-dispute:hover
  .cp-tab__table-body-content-dispute__remove {
  visibility: visible;
}

.cp-tab__table-body--no-scroll {
  overflow: hidden !important;
}

.cp-tab__table-body-content-setting {
  display: table-row;
  /* margin-top: 82px; */
  border-bottom: 0.5px solid #cdced9;
  border-left: 0.5px solid #cdced9;
  border-right: 0.5px solid #cdced9;

  /* border-radius: 5px; */
  justify-content: center;
  align-items: center;
  padding: 5px;
  text-align: center;
  background-color: #fff;
  // height: 50px;

  /* overflow: hidden; */
}

.cp-tab__table-body-content--credit {
  // border-left: 1px solid #cdced9;
  // border-right: 1px solid #cdced9;

  display: table-row;
  /* margin-top: 82px; */
  // border-bottom: 0.5px solid #cdced9;
  /* border-radius: 5px; */
  justify-content: center;
  align-items: center;
  padding: 5px;
  text-align: center;
  background-color: #fff;
  height: 50px;
  /* overflow: hidden; */
}

.cp-tab__table-body-content--credit {
  display: table-row;
  //  margin-top: 25px;
  // border-bottom: 0.5px solid #cdced9;
  /* border-radius: 5px; */
  justify-content: center;
  align-items: center;
  // padding: 5px;
  text-align: center;
  background-color: #fff;
  height: 60px;
  /* overflow: hidden; */
  gap: 20px;
}

.cp-tab__table-body {
  // display: table-row-group;
  height: 355px;
  // height: auto;
  overflow: scroll;
}

.cp-tab__adhoc_table-body {
  height: 60vh;
  overflow: scroll;
}

.cp-tab__adhoc_table-body--contact-body {
  height: 54vh;
}

.payment__table {
  height: calc(100vh - 445px);
  overflow: scroll;
}

.cp__customer_portal {
  color: #516beb;
  text-decoration: underline;
  cursor: pointer;
  width: 125px;
  // overflow: "hidden",
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cp__customer_portal svg path {
  fill: #4b4f72;
}

.cp-tab__table-body-cell {
  width: auto;
  display: flex;
  justify-content: end;
  align-items: center;
}

.cp-tab__table-body-cell--credit {
  height: 40px;
  width: 130px !important;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  border-radius: 5px;
  border: 0.5px solid #cdced9;
  margin-top: 15px;
}

// .customer-profile-card {
//   background-color: #ffffff;
//   min-width: 350px;
//   border-top-left-radius: 5px;
//   border-top-right-radius: 5px;
//   border-top: 0.5px solid #CDCED9;
//   border-left: 0.5px solid #CDCED9;
//   border-right: 0.5px solid #CDCED9;
// }

// .customer-profile__card-content {
// overflow: scroll;
// height: 100%;
// grid-area: card;
// padding: 20px;
// }

.customer-profile__card-due-content {
  // padding-top: 10px;
  // padding-left: 20px;
  // padding-right: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.--card__modifier {
  flex-direction: row;
  padding: 0px 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cp-card__pie-chart-content {
  padding-top: 15px;
  display: flex;
  justify-content: center;
}

.cp-card__pie-chart {
  width: 66px;
  height: 66px;
  margin-top: -10px;
}

.cp-card__pie-chart-details {
  padding-left: 28px;
}

.cp-card__pie-chart-values {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cp-card__pie-chart-header {
  font-size: 16px;
  color: #adadad;
}

.cp-card__pie-chart-inv {
  padding-left: 7px;
}

.cp-card__tag {
  display: flex;
  padding: 10px 0px;
  flex-wrap: wrap;
}

.cp-card__pie-chart-inv-count {
  background-color: #eeeeee;
  height: 16px;
  width: 44px;
  border-radius: 5px;
  text-align: center;
}

.cp-card__pie-chart-inv-amount {
  color: #333860;
  font-size: 25px;
  font-weight: bold;
  display: flex;
  gap: 5px;
  // padding-top: 5px;
  // text-align: center;
}

.cp-card__pie-chart-inv-amount span {
  line-height: 12px;
  font-size: 16px;
  font-weight: 400;
  margin-left: 5px;
}

.cp-card__pie-chart-inv-amount sup {
  line-height: 12px;
  font-size: 10px;
  font-weight: 400;
  color: #adadad;
}

.cp-card__overdue-total-inv-amt sup {
  line-height: 12px;
  font-size: 10px;
  font-weight: 400;
  color: #adadad;
}

.cp-card__overdue-total-inv-amt span {
  margin-left: 5px;
}

.--next_action svg path {
  fill: #4b4f72;
}

.cp-card__dues {
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 20px;
}

.cp-card__dues-combination {
  display: flex;
  justify-content: space-between;
}

.cp-card__total-due {
  display: flex;
  // justify-content: center;
  align-items: center;
}

.cp-card__total-due-level--due {
  width: 4px;
  height: 20px;
  background-color: #a8b5f5;
  border-radius: 3px;
  margin-right: 10px;
}

.cp-card__due {
  color: #adadad;
  padding-left: 10px;
}

.cpc__due-inv {
  padding-left: 5px;
}

.cp-card__due-total-inv {
  background: #e5eaff;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
  color: #516beb;
  padding: 3px 3px;
  margin-left: 5px;
}

.cp-card__due-total-amt {
  color: #333860;
  font-size: 16px;
  font-weight: bold;
  padding-top: 15px;
  text-align: center;
}

.cp-card__total-overdue {
  display: flex;
  // justify-content: center;
  align-items: center;
}

.cp-card__total-due-level {
  width: 4px;
  height: 20px;
  background-color: #fe9b95;
  border-radius: 3px;
  margin-right: 10px;
}

.cp-card__overdue {
  color: #adadad;
  padding-left: 10px;
}

.cp-card__overdue-inv {
  padding-left: 5px;
}

.cp-card__overdue-total-inv {
  background: #e5eaff;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
  color: #516beb;
  padding: 3px 3px;
}

.cp-card__overdue-total-inv-amt {
  padding-top: 10px;
  text-align: center;
  display: flex;
  gap: 5px;
  padding-left: 15px;
}

.cp-card__actions {
  // padding-top: 10px;
  display: flex;
  flex: 1;
}

.cp-card__relation-action {
  display: flex;
  // justify-content: space-between;
  align-items: start;
  flex-direction: column;
  flex: 1;
  // border-right: 0.5px solid #c7c7c7;
  gap: 10px;
}

.cp-card__action-header {
  padding-top: 10px;
  color: #adadad;
}

.cp-card__workflow-action {
  display: flex;
  justify-content: space-between;
  // padding-top: 10px;
  align-items: start;
  flex-direction: column;
  flex: 3;
}

.cp-card__workflow-action-overview {
  display: flex;
  justify-content: space-between;
  // padding-top: 10px;
  align-items: start;
  flex: 1;
  flex-direction: column;
}

.cp-card__workflow-header {
  color: #adadad;
}

.cp-card__workflow-edit {
  display: flex;
  // justify-content: end;
  align-items: center;
  flex: 1;
}

.cp-card__workflow-edit-text p {
  // min-width: 115px;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: inline-block;
  margin: 0 0px 0 0px;
  text-align: center;
}

.cp-card__workflow-edit-text-actions div {
  width: 138px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: inline-block;
  // margin: 0 5px 0 5px;
  text-align: center;
}

.cp-card__customer-next-status-content-text p {
  // min-width: 115px;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: inline-block;
  margin: 0 0px 0 0px;
  text-align: center;
}

.cp-card__workflow-edit-icon {
  padding-left: 7px;
  display: flex;
}

.section-one-content {
  display: flex;
  justify-content: space-between;
}

.section-title {
  font-size: 13px;
  color: #adadad;
  /* font-weight: bold; */
}

.content-header__back {
  display: flex;
  gap: 10px;
  align-items: center;
}

.content-header__back svg {
  height: 28px;
  width: 28px;
}

.content-header__back svg path {
  fill: #333860;
}

.content-header__back a {
  padding-top: 4px;
}

.section-value {
  padding-top: 5px;
  font-weight: bold;
  color: "#333860";
}

.section-two-content {
  padding-top: 20px;
}

.section-two-content__one {
  display: flex;
  justify-content: space-between;
}

.section-two-content__one-header {
  color: #adadad;
  font-size: 13px;
}

.section-two-content__one-header-two {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.section-two-content__two-header {
  padding-right: 5px;
  font-size: 13px;
  font-weight: 400;
  color: #adadad;
}

.section-two-content__one-value {
  color: #333860;
  font-weight: bold;
  font-size: 16px;
}

.section-two-content__two {
  display: flex;
  align-items: center;
}

.customer-profile__header {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #516beb;
  padding: 20px 0px;
  border-bottom: 0.5px solid #cdced9;
  box-shadow: 0px 1px 4px 0px #33386025;
  background-color: #ffffff;
}

// .actions-section {
//   display: flex;
//   // padding: 20px 0px;
//   padding-top: 25px;
//   // padding-bottom: 18px;
//   justify-content: space-between;
//   // padding-top: 20px;
//   border-bottom: 0.5px dashed #cdced9;
//   border-spacing: 15px;
// }
.cp-card__customer-status {
  display: flex;
  // padding: 20px 0px;
  padding-top: 10px;
  // padding-bottom: 18px;
  // justify-content: space-between;
  // padding-top: 20px;
  // border-bottom: 0.5px dashed #cdced9;
  border-spacing: 15px;
  flex: 1;
  gap: 35px;
}

.cp-card__customer-previous-status {
  flex: 1;
  // border-right: 0.5px solid #c7c7c7;
  height: 55px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cp-card__customer-previous-status-overview {
  flex: 1;
  // border-right: 0.5px solid #c7c7c7;
  height: 55px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.--center {
  align-items: flex-start;
}

.cp-card__customer-previous-status-overview-bottom {
  flex: 1;
  // border-right: 0.5px solid #c7c7c7;
  height: 55px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

// .action-section__one {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
.cp-card__customer-previous-status-icn {
  // display: flex;
  justify-content: center;
  align-items: center;
}

// .cp-card__customer-previous-status-header {
//   padding-left: 5px;
// }

.cp-card__customer-previous-status-days {
  padding-left: 3px;
  color: #0fb158;
  font-weight: bold;
}

.cp-card__customer-previous-status-content {
  // display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cp-card__customer-previous-status-letter {
  font-weight: 400;
  color: #333860;
}

.cp-card__customer-status-content-icn {
  display: flex;
  // margin-bottom: 18px;
  align-items: center;
  justify-content: center;
}

// .action-section-value-icon {
//   display: flex;
//   padding-top: 10px;
//   margin-bottom: 18px;
// }
.cp-card__customer-status-date {
  padding-left: 5px;
}

.cp-card__customer-next-status {
  // padding-top: 10px;
  // flex: 1;
  // padding-left: 15px;
  flex: 1;
  height: 55px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // border-right: 0.5px solid #c7c7c7;
}

// .cp_resume__action-btn {
//   width: 270px;
// }

.cp_resume__action-btn svg path {
  fill: #fd372a !important;
}

.cp_resume__action-btn:hover svg path {
  fill: #516beb !important;
}

.cp_pause__action-btn {
  width: 270px;
}

.cp-card__customer-next-status-icn {
  // display: flex;
  justify-content: center;
  align-items: center;
}

.cp-card__customer-next-status-icn-center {
  // display: flex;
  justify-content: center;
  align-items: center;
}

.cp-card__customer-next-status-header {
  padding-left: 5px;
}

.cp-card__customer-next-status-due {
  padding-left: 3px;
  color: red;
  font-weight: bold;
}

.cp-card__customer-next-status-content {
  // display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cp-card__customer-next-status-letter {
  font-weight: 400;
  color: #333860;
}

// .action-section__header {
//   padding-top: 10px;
//   padding-right: 15px;
//   border-right: 0.5px dashed #cdced9;
// }

// .cp-card__customer-previous-status {
// padding-top: 13px;
// padding-left: 20px;
// border-right: 0.5px dashed #cdced9;
// flex: 1;
// }

.cp-card__tags {
  display: flex;
  // padding-top: 25px;
  // padding-bottom: 25px;
  flex-direction: column;
  margin-bottom: 15px;
}

.cp-card__tags-headers {
  display: flex;
  align-items: center;
}

.cp-card__tags-icn {
  font-size: 16px;
  font-weight: bold;
  padding-right: 5px;
}

.card-tab-content__address-details {
  display: flex;
  align-items: center;
}

.card-tab-content__address-header {
  font-size: 16px;
  font-weight: bold;
  padding-right: 5px;
}

.c-t-c__address-content {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
}

.c-t-c__address {
  color: #adadad;
  font-weight: bold;
}

.c-t-c__address-text {
  padding-top: 10px;
}

.card-tab-content__phone-details {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.c-t-c__phone-header {
  font-size: 16px;
  font-weight: bold;
  padding-right: 5px;
}

.phone-content {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
}

.tab-phone-content-one {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
}

.c-t-c__phone-content-office {
  padding-top: 15px;
  padding-bottom: 10px;

  display: flex;
  justify-content: space-between;
}

// .c-t-c__phone-content {
//   padding-top: 15px;
//   display: flex;
//   justify-content: space-between;
// }
.card-tab-content__email-details {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.c-t-c__email-header {
  font-size: 16px;
  font-weight: bold;
  padding-right: 5px;
}

.c-t-c__email-content {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
}

.c-t-c__email-content-office {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
}

// .circle {
//   width: 50px;
//   height: 50px;
//   border-radius: 50%;
//   // border: 2px solid #516beb;
//   background: #516beb;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .circle {
//   position: relative;

//   width: 50px;
//   height: 50px;
//   border-radius: 50%;
//   // border: 2px solid #516beb;
//   background: #516beb;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
.circle {
  // display: inline-block;
  // width: 35px;
  // height: 35px;
  // border-radius: 50%;
  // margin: 0 5px;
  position: relative;

  width: 50px;
  height: 50px;
  border-radius: 50%;
  // border: 2px solid #516beb;
  background: #516beb;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-active svg path {
  fill: #ffffff;
}

.action-executed svg path {
  fill: #adadad;
}

.circle::before {
  content: "";
  position: absolute;
  width: 55%;
  height: 55%;
  background-color: inherit;
  border-radius: 50%;
  z-index: -1;
  animation: ripple 1.5s ease-out infinite;
  background-color: #516beb;
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }

  to {
    opacity: 0;
    transform: scale(3);
  }
}

.cp-timeline-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 0px;
}

// .cp-timeline-content--no-margin {
//   margin: 0px;
// }

.cp-timeline__filters {
  display: flex;
  gap: 10px;
}

.cp-timeline-contant__bot-status-view {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.cp-status-view__bot-icon {
  background-color: #faeded;
  border-radius: 25px;
  height: 45px;
  padding: 12px;
}

.cp-status-view__bot-icon svg path {
  fill: #fd372a;
}

.cp-status-view__bot-discretion {
  background-color: rgb(250, 237, 237);
  border-radius: 5px;
  width: 605px;
  box-shadow: rgb(51 56 96 / 14%) 1px 2px 4px 0px;
  padding: 10px 20px 10px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  border: 0.5px solid #cdced9;
}

.cp-discretion__bot-alert-ic {
  height: 100%;
  padding-top: 5px;
}

.cp-discretion__bot-text-content {
  padding-right: 80px;
}

.cp-discretion__bot-text-content__weight {
  font-weight: 400;
  font-size: 16px;
}

.cp-discretion__bot-text-content__sz-cl {
  font-size: 16px;
  color: #fd372a;
}

.cp-status-view__day {
  display: flex;
  align-content: flex-end;
  flex-wrap: wrap;
}

// template-status-style-start

.cp-timeline-contant__status-view {
  display: flex;
  gap: 20px;
  margin-top: 15px;
}

.cp-status-view__icon {
  background-color: #e5eaff;
  border-radius: 25px;
  height: 45px;
  padding: 12px;
}

.cp-status-view__icon_field_visit {
  background-color: #f0d2d0;
  border-radius: 25px;
  height: 45px;
  padding: 12px;
}

.cp-status-view__discretion {
  background-color: #ffffff;
  border-radius: 5px;
  width: 605px;
  box-shadow: rgb(51 56 96 / 14%) 1px 2px 4px 0px;
  padding: 10px 25px 10px 25px;
  display: flex;
  align-items: center;
  gap: 5px;
  border: 0.5px solid #cdced9;
  flex-direction: column;
}

.cp-status-view__discretion_dispute {
  background-color: #ffffff;
  border-radius: 5px;
  width: 605px;
  box-shadow: rgb(51 56 96 / 14%) 1px 2px 4px 0px;
  display: flex;
  align-items: center;
  gap: 5px;
  border: 0.5px solid #cdced9;
  flex-direction: column;
}

.dairy-note-alert {
  background: #ffffff;
  // width: 605px;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  // border-radius: 5px;
}

// .cp-discretion__text-content {
//   padding-right: 10px;
//   // width: 650px;
// }

.diary__notes__alert-svg svg {
  width: 18px;
  height: 18px;
}

.cp-discretion__text-content__weight {
  font-weight: 400;
  font-size: 16px;
}

.cp-text-content__text-deliver {
  display: flex;
  align-items: center;
}

.cp-discretion__profile {
  background-color: #dde3ff;
  padding: 8px;
  height: 40px;
  border-radius: 50px;
}

.cp-status-view__day {
  display: flex;
  align-content: flex-end;
  flex-wrap: wrap;
}

.cp-timeline-container__status {
  // height: 82%;
  height: 78%;

  overflow-y: scroll;
  flex-direction: column;
  flex: 1;
  padding-bottom: 25px;
  // height: 100vh;
  // overflow: scroll;
}

.cp-timeline-contant__status-view__ft-r {
  display: flex;
  flex-direction: row-reverse;
  gap: 15px;
  margin-top: 20px;
}

.cp-timeline_mark-as-read {
  position: absolute;
  top: 0px;
  right: 0px;
}

.dummy-height__overflow-bottom {
  height: 20px;
  background-color: #ffffff;
}

// template-status-style-end
// .isexpand {
//   transition: max-height 3s ease-in;
//   height: auto;
//   max-height: 250px;
// }

// .collapsed {
//   max-height: 0px;
// }

// .section {
//   overflow: hidden;
//   transition: max-height 3s ease-out; // note that we're transitioning max-height, not height!
//   height: auto;
//   max-height: 700px; // still have to hard-code a value!
// }
// .section .isexpand {
//   transition: max-height 3s ease-out;
//   max-height: 0px;
// }

// @-webkit-keyframes SHOW-BOX {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }
// @-moz-keyframes SHOW-BOX {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }
// @-o-keyframes SHOW-BOX {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }
@keyframes SHOW-BOX {
  0% {
    height: 10px;
  }

  100% {
    height: 80px;
  }
}

.show-box {
  // -webkit-animation: SHOW-BOX 0.3s ease;
  // -moz-animation: SHOW-BOX 0.3s ease;
  // -o-animation: SHOW-BOX 0.3s ease;
  animation: SHOW-BOX 0.3s ease-in-out;
}

.cp-expand__lable {
  width: 10%;
  text-align: right;
}

.cp-discretion-expand-content {
  margin-top: 15px;
}

.cp-discretion-expand__view-field {
  display: flex;
  padding: 5px;
  border: 0.5px solid rgb(205, 206, 222);
  border-radius: 5px;
  margin-bottom: 10px;
}

.cp-expand__cont {
  padding-left: 15px;
}

.cp-discretion-expand__invoice-view-field {
  display: flex;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.cp-expand__invoice-lable {
  width: 25%;
  text-align: right;
}

.cp-invoice-alert {
  background-color: #fd372a;
  padding: 4px;
  border-radius: 25px;
  height: 6px;
  margin-right: 5px;
}

.cp-invoice-due {
  display: flex;
  align-items: center;
}

.cp-action-switch-content {
  padding: 40px 40px;
}

.cp-action-title--content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.cp-action-instruction p {
  width: 100%;
}

.cp-action-instruction {
  // background-color: #fafafa;
  // padding: 15px;
  border-radius: 5px;
}

.cp-action-reciever-content {
  display: flex;
  margin-top: 5px;
  // margin-bottom: 25px;
}

.cp-action-select {
  width: 100%;
  padding-right: 20px;
}

.cp-action-switch-content {
  overflow: scroll;
  height: 30%;
}

.cp-action-outcome-content {
  display: flex;
  // justify-content: space-between;
  gap: 5px;
}

// .cp-action-outcome-content svg {
//   width: 30px;
//   height: 30px;
// }

.cp-action-outcome {
  margin-bottom: 25px;
}

.cp-action-promise-amount_input {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cp-action-pay_action {
  display: flex;
  align-items: center;
  gap: 10px;
}

// .action-promise-amount--input {
//   margin-top: 10px;
// }
.cp-action-message-confirmation {
  // padding-top: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  // height: 55px;
  // margin-bottom: 15px;
}

.cp-action-status {
  width: 151px;
}

.cp-action-message-confirmation-calendar {
  display: flex;
  gap: 20px;
  align-items: center;
}

.cp-message-confirmation-checkbox {
  // width: 25%;
  margin-bottom: 5px;
}

.cp-message-confirmation-type {
  width: 90px;
  // margin-top: 15px;
}

.cp-action-promise-pay-con {
  // margin-top: 15px;
  width: 260px;
}

.cp-quill {
  height: 330px;
}

.cp-quill--action-view {
  height: 400px;
}

.--cp-cnt-quill .ql-editor {
  padding: 0px 15px 12px 15px;
}

.cp-quill .quill {
  height: 100% !important;
}

.cp-quill .quill .ql-container {
  overflow: auto;
}

.cp-quill-subject .ql-container {
  border-radius: 5px;
}

.note-ql-container .ql-container {
  border: none;
}

.note-ql-container .ql-placeholder {
  // font-style: normal;
  // font-family: normal;
}

.cp-action-switch-container {
  height: 100%;
  overflow: scroll;
  padding: 20px 30px;
}

.cp-action-switch-container-review {
  height: 100%;
  overflow: scroll;
  // padding: 20px 30px;
}

.cp-action-switch-footer {
  // display: flex;
  // justify-content: flex-end;
  background-color: #f9f9f9;
  // height: 73px;
  align-items: center;
  padding: 10px;
}

.cp-action-btn {
  min-width: 126px;
}

// .cp-action-switch-container form {
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
// }
.cp-action-promise_test-area {
  margin-top: 10px;
}

.cal--select {
  width: 135px;
}

.cp-action-callback {
  width: 135px;
  margin-top: 15px;
}

.cp-action-dispute__service {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cp-action-dispute__select-servise {
  width: 605px;
}

.cp-dispute__select-status {
  width: 270px;
}

.modifi--select-status {
  display: flex;
  align-items: center;
}

.cp-card__tag-align {
  justify-content: space-between;
  align-items: center;
  //  margin-top: 30px;
}

.cp-total-tag {
  background-color: #f5f6fa;
  height: 22px;
  width: 22px;
  text-align: center;
  border-radius: 5px;
}

.cp-card__tag-holder {
  display: flex;
  align-items: center;
}

.cp-tag-card-border {
  padding-bottom: 0px;
  // border-bottom: 0.5px solid #cdced9;
  margin-bottom: 5px;
  padding-right: 10px;
}

.cp-card__contact-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.contact-btn {
  display: none;
}

.contact-name {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.contact-user {
  padding-top: 15px;
  padding-right: 10px;
  display: block;
}

.cp-card-conatiner:hover .contact-user {
  display: none;
}

.cp-card-conatiner:hover .contact-name {
  max-width: 35px;
  min-width: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cp-card-conatiner:hover .contact-btn {
  display: flex;
  position: relative;
  right: 0;
  top: 4px;
}

.cp-contact__d-flex p {
  min-width: 110px;
  max-width: 170px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cp-contact__d-flex svg path {
  fill: #4b4f72;
  width: 15px;
  height: 15px;
}

.cp-contact__d-flex {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  align-items: center;
}

.cp-card-hiper-link {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.cp-card-hiper-link a {
  color: #516beb;
  font-size: 12px;
  font-weight: 400;
}

.cp-card-conatiner {
  padding: 0px 0px 5px 10px;
  border: 0.5px solid #cdced9;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 1px 2px 4px #33386025;
  width: 200px;
}

.cp-card-hiper-link svg path {
  fill: #ffffff;
  stroke: width 0;
}

.cp-card-hiper-link svg path {
  fill: #ffffff;
  stroke-width: 0;
}

.cp-card-conatiner:hover .cp-card-hiper-link svg path {
  fill: #adadad;
  stroke: #adadad;
}

.cp-unsuccessful-msg-sec {
  margin-bottom: 15px;
}

.cp-action-call-outcome {
  border: 1px solid #cdced9;
  height: 36px;
  width: 100px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cp-action-call-outcome div {
  color: #333860;
}

.cp-action-call-outcome svg path {
  fill: #333860;
}

.cp-action-call-outcome--active {
  border: 2px solid #516beb;
  height: 36px;
  width: 100px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cp-action-call-unsuccessful {
  border: 1px solid #cdced9;
  height: 36px;
  width: 122px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cp-action-call-unsuccessful--active {
  border: 2px solid #516beb;
  height: 36px;
  width: 122px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.circ-clr-wts {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #dbeadc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disable-circ-clr-wts {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disable-circ-clr-wts svg path {
  fill: #adadad;
}

.cp-action-call-unsuccessful--active {
  color: #516beb;
  font-weight: bold;
}

.cp-action-call-outcome--active div {
  color: #516beb;
}

.cp-action_unsuccess--setdate {
  height: 46px;
  width: 125px;
  border: 0.5px solid #cdced9;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  user-select: none;
  cursor: pointer;
}

.cp-action-call-outcome--active-red {
  border: 2px solid #fd372a;
  height: 36px;
  width: 100px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cp-action-call-outcome--active-red div {
  color: #fd372a;
}

.cp-action-call-outcome--active-red svg path {
  fill: #fd372a;
}

.onHov:hover {
  background-color: #f5f7ff;
  border-radius: 5px;
}

.onHov :hover svg path {
  fill: #ffffff !important;
}

.onHov :hover .circ-clr-wts {
  background-color: #4caf50 !important;
}

.onHov :hover .circ-clr {
  background-color: #516beb !important;
}

.onHov:hover .clr-text {
  color: #516beb !important;
}

.c {
  color: #adadad !important;
  user-select: none;
}

.enabled {
  color: #333860 !important;
}

.svgQuickActionDisable {
  background: #e3e3e3;
}

.svgQuickActionEnable {
  background: #eaeeff;
}

.svgQuickActionWhatsAppEnable {
  background: #dbeadc;
}

.svgQuickActionDisableBox {
  background: #e3e3e3;
  box-shadow: "";
}

.svgQuickActionEnableBox {
  background: #ffffff;
  border: 0.5px solid #cdced9;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}

.disable {
  color: #adadad !important;
}

.quick-action {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
}

.quick-action-pointer {
  pointer-events: none;
}

// .cp-card__total-mail{
//   background-color: #516beb;
//   padding: 3px;
//   border-radius: 5px;
//   margin-left: 12px;
//   color: #fff;
// }
.enabled-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #eaeeff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disable-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disable-circle svg path {
  fill: #adadad !important;
}

.cp-card__total-mail {
  background-color: #ffffff;
  text-align: center;
  border-radius: 5px;
  margin-left: 12px;
  // padding: 0px 6px 0px 6px;
  width: 24px;
  height: 14px;
  border: 1px solid #516beb;
}

.cp-card__total-mail div {
  font-size: 13px;
}

.cp-payment-content {
  display: flex;
  justify-content: end;
  padding-right: 30px;
  margin-bottom: 20px;
}

.cp-add-payment__btn {
  margin-right: 15px;
  width: 160px;
}

.cp-payment__icon {
  margin-right: 5px;
  margin-top: 0px;
}

.cp-add-payment__btn svg path {
  fill: #516beb;
}

.cp-add-payment__btn svg {
  margin-right: 10px;
}

.cp-payment-tab__table {
  display: block;
  position: relative;
  overflow-x: scroll;
  overflow-y: scroll;
  /* height: 458px; */
  height: 100%;
  text-align: center;
}

.cp-payment__filter {
  display: flex;
  justify-content: end;
}

.cp-card-hiper-link a {
  text-decoration: underline;
  cursor: pointer;
}

.cp-card-hiper-link svg path {
  fill: #ffffff;
}

.dairy-ovr .cp-card-hiper-link svg path {
  fill: #adadad;
}

.word-space {
  display: -webkit-box;
  // word-break: break-all;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.word-popup {
  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 450px;
}

// .on-expand {
//   // word-break: break-all;
// }

.access_nth_child:nth-child(1) .table-main__head-cell {
  width: 30px !important;
}

.cp-contact-details__add-btn button:hover svg path {
  fill: #ffffff;
}

.cp-contact-details__add-btn button svg path {
  fill: #333860;
}

.cp-discretion__icon-alert svg path {
  fill: #adadad;
}

.cp-expand__form-input {
  margin-top: 10px;
}

.cp-expand__type {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.cp-expand__confir-check {
  margin-top: 20px;
  margin-bottom: 20px;
}

.cp-expand__confirm-input {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
  margin-left: 30px;
}

.cp-expand__type-input {
  width: 155px;
}

.cp-expand__promise-confirm {
  width: 300px;
}

.cp-expand__rec-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.--width-modified {
  width: 320px;
}

.cp-card__default-click h6 {
  color: #ffffff;
  cursor: pointer;
}

.cp-card-conatiner:hover h6 {
  color: #516beb;
}

.cp-tab__table-haeder-sort div:nth-child(1) {
  width: 100%;
}

.setting-active {
  border-bottom: 0.5px solid #f0f5ff;
  // border-radius: 5px;
  // margin-bottom: 20px;
  // height: 100px;
  padding: 15px;
  cursor: pointer;
  box-shadow: 0px 1px 3px 0px #0000001a, 0px 1px 2px 0px #0000000f;
  background-color: #f0f5ff;
}

.setting-active .is-configured {
  // width: 100px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-weight: bold;
  background-color: #ffffff;
  color: #0fb158;
}

.setting-active .label {
  color: #333860;
}

.setting-active .des {
  color: #ffffff;
}

.setting-inactive {
  border-bottom: 0.5px solid #cdced9;
  // border-radius: 5px;
  // margin-bottom: 20px;
  // height: 100px;
  padding: 15px;
  cursor: pointer;
  // box-shadow: 0px 1px 3px 0px #0000001a, 0px 1px 2px 0px #0000000f;
}

.setting-inactive .des {
  color: #adadad;
}

.setting-inactive:hover {
  // border: 0.5px solid #516beb;
  background-color: #f0efef;
}

.setting-inactive .label {
  color: #333860;
}

.setting-inactive .is-configured {
  // border: 0.5px solid #0fb158;
  // color: #0fb158;
  // width: 100px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-weight: bold;
  background-color: #ffffff;
}

.nested-view-content-icon-payment:hover svg path {
  fill: #fd372a !important;
}

// .cp-tab__table-body-content:hover svg path {
//   fill: #adadad;
// }

.cp-tab__table-body-content svg path {
  fill: #333860;
}

// .cp-tab__table-body-content svg path{
//   fill:#ffffff
// }
.cp-tab__table-body-content:hover .cp-tab__table-body-cell:nth-child(9) {
  width: 30px !important;
}

.cp-tab__table-body-content:hover .payment-value {
  white-space: nowrap;
  width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: inline-block;
  margin-top: 3px;
}

.cp-tab__table-body-content .payment-value {
  white-space: nowrap;
  width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: inline-block;
  margin-top: 3px;
}

.action-button {
  display: none;
  // position: absolute;
}

// .cp-tab__table-body-content
.cp-tab__table-body-content:hover .action-button {
  display: flex;
  // align-items: center;
  // justify-content: flex-end;
  position: absolute;
  right: 0%;
}

// .cp-tab__table-body-content .cp-tab__table-body-cell:nth-child(7) p{
//   white-space: nowrap;
//   width: 50px;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   position: relative;
//   display: inline-block;
//   margin-top: 3px;
// }
.workflow-name {
  white-space: nowrap;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.svg-primary-fill path {
  fill: $primary !important;
}

.cp-tab__table-header-cell-content {
  width: 100px !important;
}

.payment-popup_btn {
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.payment-popup_btn svg path {
  fill: #333860 !important;
}

.payment-popup_btn:hover {
  background-color: #f0f3f8;
}

.content-header__back {
  display: flex;
  gap: 10px;
  align-items: center;
}

.content-header__back svg {
  height: 28px;
  width: 28px;
}

.content-header__back svg path {
  fill: #333860;
}

.content-header__back a {
  padding-top: 4px;
}

.--next_action {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
}

.cp__table__overview__wraper {
  display: flex;
  width: calc(100% - 60px);
}

.cp__customerm__tabel-container {
  display: flex;
  background-color: #ffffff;
  width: 250px;
  flex-direction: column;
  height: 100vh;
  width: 250px;
}

.cp__customer__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // flex: 1;
  height: 70px;
  border-bottom: 0.5px solid #c7c7c7;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.cp_currency__style {
  font-size: 10px;
  font-weight: 400;
  // margin-top: 2px;
  color: #adadad;
}

.cp__back_btn_wraper {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  padding-left: 10px;
}

.cp__back_btn_wraper div {
  margin-top: 3px;
}

.cp__customer__list-wraper {
  height: 100%;
  overflow: scroll;
  width: 100%;
}

.cp__customer__container:hover {
  background-color: #f0f5ff;
  cursor: pointer;
}

.custmer__active {
  background-color: #f0f5ff;
}

// global
.cp__globalsearch {
  // flex: 1;
  // height: 11%;
  border-bottom: 0.5px solid #c7c7c7;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 99px;
}

.recent_visit_container {
  flex: 1;
  padding: 7px 10px 7px;
  height: 50px;
  border-bottom: 0.5px solid #c7c7c7;
}

.recent_visit_container:hover {
  background-color: #f0f5ff;

  cursor: pointer;
}

.cp__globalsearch:hover {
  background-color: #f0f5ff;
  cursor: pointer;
}

.--cp-change__direction {
  flex-direction: column;
}

.customer-overview_padding {
  padding: 0px 10px;
}

.cp-total__amount__container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.cp-total__amt-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.cp-card__action_wraper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  border-bottom: 0.5px solid #c7c7c7;
  padding-bottom: 15px;
}

.cp-card__action__amount {
  display: flex;
  gap: 10px;
}

.cp-card__customer_action {
  display: flex;
  gap: 20px;
}

.cp-allaction__wraper {
  gap: 5px;
  display: flex;
  flex-direction: column;
}

.cp__action__wraper_valied {
  display: flex;
  border-bottom: 0.5px solid #c7c7c7;
  padding-bottom: 10px;
}

.--cp_overview__head {
  padding-bottom: 5px;
}

.cp__chart__wraper {
  display: flex;
  gap: 10px;
  flex-direction: column;
  background-color: #f0f5ff;
  border: 0.5px solid #cdced9;
  border-radius: 5px;
  box-shadow: 1px 2px 4px 0px #33386025;
  padding: 10px 10px;
  // justify-content: space-between;
  min-width: 175px;
  // width: 480px;
}

.cp__details__amt {
  display: flex;
  // gap: 10px;
}

.cp__top__section__wraper {
  display: flex;
  gap: 10px;
}

.cp_right__card__wraper {
  display: flex;
  // gap: 10px;
  flex-direction: column;
  background-color: #ffffff;
  border: 0.5px solid #cdced9;
  border-radius: 5px;
  box-shadow: 1px 2px 4px 0px #33386025;
  padding-top: 10px;
  flex: 1;
  min-width: 175px;
  position: relative;
}

.right__card__top_conatiner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #c7c7c750;
}

.right__card__bottom_conatiner {
  display: flex;
  padding-top: 5px;
  justify-content: space-between;
  // align-items: center;
}

.contact__details__wraper {
  flex: 1;
}

.cp__tab__container {
  background-color: #ffffff;
  flex: 5;
  border: 0.5px solid #cdced9;
  box-shadow: 1px 2px 4px 0px #33386025;
  border-radius: 5px;
  overflow: hidden;
  min-width: 600px;
}

.cp__contact__container_right {
  display: flex;
  background-color: #ffffff;
  padding: 18px 7px;
  border-radius: 5px;
  border: 0.5px solid #cdced9;
  box-shadow: 1px 2px 4px 0px #33386025;
  flex: 1;
  overflow: auto;
}

.cp__resume__btn {
  text-align: end;
  flex: 1 1 0%;
  display: flex;
  justify-content: center;
  // border-right: 1px solid #c7c7c7;
  height: 50px;
  align-items: center;
}

.--modifier__border {
  border-right: 0px;
  align-items: center;
}

.--layout_modifier .cp__customerm__tabel-container {
  height: 95vh;
}

.cp_alert__color svg path {
  fill: #fbbd04 !important;
}

.cp__resume__btn svg path {
  fill: #516beb;
}

.--adhoc__table__h {
  overflow: scroll;
}

.--cp__customer__overview {
  width: calc(100% - 250px);
}

.cp_customer__action__pause {
  position: absolute;
  text-align: center;
  background-color: #fd372a;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 191px;
  color: #ffffff;
  left: 509px;
  bottom: -6px;
  border: 0.5px solid #cdced9;
}

.--modifi__card_r {
  border-top-left-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.--cp__invoice__body {
  height: calc(100vh - 465px) !important;
  overflow: scroll;
}

.contact__card_scroll {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  max-height: 270px;
}

.cp__invoice__table--modifear .table-main__head-cell {
  // flex: 100 0 auto !important;
  width: 80px !important;
}

.--cus__currencyb {
  text-overflow: ellipsis;
  overflow: hidden;
}

.--next_action svg {
  width: 18px;
  height: 18px;
}

.cp-left__customer__list {
  display: flex;
  gap: 5px;
  align-items: center;
}

.cp-left__customer__list svg {
  width: 18px;
  height: 18px;
}

.cp-left__customer__list svg path {
  fill: #516beb;
}

.cp-discretion__text-content {
  width: 100%;
}

.--inv__modifier {
  height: 80px;
}

.add_diary_alert {
  padding-right: 8px;
  padding-top: 5px;
}

.add_diary_alert svg {
  width: 15px;
  height: 15px;
}

.add_note_alert_btn {
  border: 1px solid #cdced9;
  height: 28px;
  width: 160px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;
}

.add_note_alert_btn h6 {
  color: #adadad;
}

.add_note_alert_btn svg path {
  fill: #adadad;
}

.add_note_alert_btn--active {
  border: 2px solid #516beb;
  height: 28px;
  width: 160px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  padding: 0px 10px;
  // justify-content: center;
  align-items: center;
  cursor: pointer;
}

.add_note_alert_btn--active h6 {
  color: #516beb;
}

.cp-action-call-outcome--yellow {
  border: 2px solid #fbbd04;
  height: 28px;
  width: 160px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0px 10px;
}

.cp-action-call-outcome--yellow svg path {
  fill: #fbbd04;
}

.cp-action-call-outcome--yellow h6 {
  color: #fbbd04;
}

.cp-action-call-outcome--red {
  border: 2px solid #fd372a;
  height: 28px;
  width: 160px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0px 10px;
}

.cp-action-call-outcome--red h6 {
  color: #fd372a;
}

.cp-action-call-outcome--red svg path {
  fill: #fd372a;
}

.notes_card_category_contain {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px 0px 0px;
  margin-bottom: 5px;
}

.notes_category_alert_info {
  display: flex;
  align-items: center;
  // gap: 5px;
}

.notes_category_alert_info h6 {
  background-color: #e5e9fc;
  border-radius: 5px;
  color: #516beb;
  line-height: 22px;
  padding: 0px 5px;
}

.notes_category_alert_info p {
  background-color: #e5e9fc;
  border-radius: 5px;
  color: #516beb;
  line-height: 22px;
  padding: 0px 5px;
}

.notes_category_alert_alert {
  display: flex;
  align-items: center;
  gap: 5px;
}

.notes_category_alert_alert h6 {
  background-color: #fff5da;
  border-radius: 5px;
  color: #ecab21;
  line-height: 22px;
  padding: 0px 5px;
}

.notes_category_alert_alert p {
  background-color: #fff5da;
  border-radius: 5px;
  color: #ecab21;
  line-height: 22px;
  padding: 0px 5px;
}

.notes_category_alert_critical {
  display: flex;
  align-items: center;
  gap: 5px;
}

.notes_category_alert_critical h6 {
  background-color: #ffe9e8;
  border-radius: 5px;
  color: #fd372a;
  line-height: 22px;
  padding: 0px 5px;
}

.notes_category_alert_critical p {
  background-color: #ffe9e8;
  border-radius: 5px;
  color: #fd372a;
  line-height: 22px;
  padding: 0px 5px;
}

.drawer_close_btn button {
  color: #787878;
}

.drawer_close_btn svg path {
  fill: #787878;
}

.drawer_close_btn svg {
  width: 9px;
  height: 9px;
  margin-right: 3px;
}

.drawer_add_btn button {
  color: #516beb;
}

.drawer_add_btn svg line {
  stroke: #5156be;
}

.drawer_add_btn svg {
  width: 16px;
  height: 16px;
}

.notes_icon_btn svg {
  width: 15px;
  height: 15px;
}

.notes_icon_btn svg path {
  fill: #4b4f72;
}

.dm_select_container {
  display: flex;
  gap: 20px;
}

.cp_email_attachement {
  padding: 5px;
  background: #ffffff;
  border: 1px solid #cdced9;
  box-shadow: #00000024 0px 4px 4px;
  border-radius: 5px;
}

.cp_email_attachement p {
  cursor: pointer;
  padding: 5px;
}

.cp_email_attachement p:hover {
  background-color: #ecf0fd;
  border-radius: 5px;
}

.dms_card_container {
  padding: 5px 0px;
  border: 1px solid #cdced9;
  border-radius: 5px;
  margin-bottom: 10px;
}

.ci_card_container {
  padding: 10px 0px;
  border: 1px solid #cdced9;
  border-radius: 5px;
  margin-bottom: 10px;
}

.dms_card_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 5px;
}

.dms_card_doc_type_content {
  display: flex;
  gap: 5px;
  align-items: center;
}

.file_text_wraper {
  position: absolute;
  top: 6px;
  font-size: 6px;
  left: 7px;
  font-weight: 900;
}

.--file-name-n-p {
  position: absolute;
  top: -5px;
  font-size: 4px;
  left: 6px;
  font-weight: 900;
}

.file_wraper_icon {
  position: relative;
  display: flex;
  color: #ffffff;
}

.--doc_purple svg path {
  fill: #6f1ab6;
}

.--doc_green svg path {
  fill: #0ac963;
}

.--doc_blue svg path {
  fill: #0263d1;
}

.--doc_grey svg path {
  fill: #ededed;
}

.--doc_grey p {
  color: #333860;
  font-weight: 900;
}

.--doc_yellow svg path {
  fill: #fff89c;
}

.--doc_yellow p {
  color: #333860;
  font-weight: 900;
}

.--doc_font_size {
  font-size: 5px;
}

.file_upload_card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  padding: 20px 10px;
  margin-bottom: 15px;
  height: 101px;
}

.file_upload_icon_name {
  display: flex;
  align-items: center;
  gap: 5px;
}

.file_upload_icon_name svg {
  height: 30px;
  width: 30px;
}

.file_text_wraper--field {
  position: absolute;
  top: 8px;
  font-size: 8px;
  left: 7px;
  font-weight: 900;
}

.--doc_font_size_field {
  font-size: 7px;
}

.file_upload_close_btn {
  margin-top: 7px;
  cursor: pointer;
  padding: 5px;
}

.file_upload_close_btn svg {
  width: 13px;
  height: 13px;
}

.--dms_card_pt {
  padding: 12px 0px;
}

.--ci_card_pt {
  padding: 10px 0px;
}

.whatsapp_emoji_wraper {
  width: 100%;
}

.whatsapp_emoji_wraper div {
  flex-direction: row-reverse;
}

.whatsapp_emoji_wraper .react-input-emoji--placeholder {
  justify-content: flex-end;
}

.whatsapp_emoji_wraper .react-input-emoji--container {
  border-radius: 21px;
  border-color: rgb(234, 234, 234);
  font-size: 15px;
  font-family: sans-serif;
  // height: 10px;
}

.whatsapp_footer-part {
  display: flex;
  background: #f1f2f6;
  // padding: 10px;
  align-items: center;
}

.whatsapp_body_area {
  display: flex;
  flex-direction: column;
  height: calc(100% - 115px);
  justify-content: space-between;
}

// .whatsapp_emoji_wraper .react-input-emoji--wrapper {
//   max-height: 28px;
// }

.--timeline__footer {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 12px;
}

.--inv_add_btn {
  gap: 15px;
  align-items: center;
}

.inv_icon svg path {
  fill: #333860;
}

.whatsapp_info_alert svg path {
  fill: #fd372a;
}

.cp__invoice__table--modifear .--tax_table_body {
  width: 155px !important;
}

.approved_green svg path {
  fill: #00aa5b;
}

.approved_gray svg path {
  fill: #5c625f;
}

.work_flow_auto {
  position: absolute;
  background-color: #589f58;
  padding: 3px 5px;
  font-size: 8px;
  border-radius: 100px;
  color: #ffffff;
  bottom: -6px;
  font-weight: 900;
}

.--auto_in_queue {
  background-color: #516beb;
}

.--auto_executed {
  background-color: #adadad;
}

.--customer_overview_head_modifer h2 {
  min-width: 100px;
  max-width: 320px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.customer_search_type {
  padding: 5px 10px;
  cursor: pointer;
}

.customer_search_type:hover {
  color: #5156be;
  background-color: #f5f7ff;
}

.customer_search_type:hover p {
  color: #5156be;
  font-weight: 700;
}

.--body_modified_revune {
  height: 35px;
}

.resume_and_pause_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 5px 4px 5px;
  flex: 1;
}

.resume_and_pause_container:hover .pause_action_hide {
  display: none;
}

.resume_and_pause_container:hover .resume-hide {
  display: block;
  flex: 1;
}

.pause_action_hover {
  color: #fd372a;
  margin-left: 4px;
  display: flex;
  align-items: center;
  flex: 1;
  // justify-content: space-between;
}

.resume_hover {
  color: #516beb;
  margin-left: 4px;
  display: flex;
  align-items: center;
  flex: 1;
  // justify-content: space-between;
}

.pause_action_hide {
  flex: 1;
}

.resume-hide {
  display: none;
}

.last_customer_action-card-date {
  display: none;
}

.cp_edit_ic {
  display: flex;
  align-items: center;
}

.tick_cp_icon svg {
  height: 26px;
  width: 26px;
}

.tick_cp_icon svg path {
  fill: #0fb158;
}

.close_cp_icon svg {
  height: 16px;
  width: 16px;
}

.close_cp_icon svg path {
  fill: #fd372a;
}

.cp_pencil_icon svg {
  height: 20px;
  width: 20px;
}

@media (min-width: 1550px) {
  .last_customer_action-card-date {
    display: block;
    color: #adadad;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    font-size: 12px;
  }
}

// @media (max-width:1260px) {
//   .cp__chart__wraper{
//     width: 174px !important;
//   }
// }

.inv_custom_tab_mx {
  padding: 0px 5px;
  border: 1px solid #cdced9;
  border-radius: 5px;
  cursor: pointer;
  min-width: 100px;
  text-align: center;
  margin-bottom: 10px;
}

.--custom_inv_active {
  border: 1px solid #516beb;
}

.--custom_inv_active p {
  color: #516beb;
  font-weight: 500;
}

.paymentplancard {
  display: flex;

  align-items: center;

  justify-content: space-between;

  gap: 15px;

  border-radius: 5px;

  padding: 10px;

  border: 1px solid #cdced9;

  background: #fff;

  margin: 5px;
}

.bot_card_details {
  width: 5px;

  height: 5px;

  background-color: #adadad;

  border-radius: 5px;
}

.add_card {
  background-color: #f0f5ff;

  padding: 10px;

  border-radius: 50%;

  display: flex;

  align-items: center;
}

// notes stype for rewamp overview v2

.dairy_notes_card_wraper {
  // border: 1px solid rgb(205, 206, 217);
  //   border-radius: 5px;
  //   padding: 13px 6px 10px;
  margin-bottom: 15px;
  box-shadow: rgba(51, 56, 96, 0.145) 1px 2px 4px;
  //   user-select: none;
  // display: flex;
  width: 100%;
  // height: 144px;
  padding: 12px 0px 0px 0px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 5.5px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #cdced9;
  background: #fff;
  border: 1px solid #cdced9;
  background: #fff;
  overflow: hidden;
}

.notes_list_container {
  margin-top: 10px;
  overflow: hidden;
  padding-right: 12px;
}

.notes_card_head_wraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notes_card_icon_date_container {
  display: flex;
  align-items: center;
}

.notes-card-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dairy_notes_card_wraper :hover h6 {
  display: none;
}

.dairy_notes_card_wraper :hover .notes-card-btn {
  display: inline-block;
}

.dairy_notes_card_wraper .notes-card-btn {
  display: none;
}

.drawer_add_btn {
  margin-right: 20px;
}

.successful {
  padding: 10px 15px;
  border: 1px solid #cdced9;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  color: #333860;
}

.unsuccessful {
  padding: 10px 15px;
  border: 1px solid #516beb;
  border-radius: 5px;
  cursor: pointer;
  // background: #f0f5ff;
  background: "#F0F5FF";
  color: #5156be;
  font-weight: 500;
  font-size: 14px;
}

.outCome {
  border: 1px solid #cdced9;
  border-radius: 5px;
  padding: 5px 15px;
  // width: 120px;

  text-align: center;
  cursor: pointer;
  user-select: none;
  font-weight: 700;
  // font-weight: 500;
  // font-size: 13px;
}

.activeOutCome {
  border: 1px solid #516beb;
  border-radius: 5px;
  padding: 5px 15px;
  text-align: center;
  cursor: pointer;
  background: #f0f5ff;
  color: #5156be;
  user-select: none;
  font-weight: 700;
}

.rq-quill .quill .ql-container {
  border-radius: 5px;
}

.request_information {
  padding: 9px;
  border-bottom: 1px solid #cdced9;
}

.co-quick-action-container {
  display: flex;
  flex-direction: column;
  border-left: 1px solid $border;
}

.co-quick-action-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 450px;
  // max-height: 350px;

  overflow-y: scroll;
}

.co-quick-action-container::-webkit-scrollbar {
  display: none;
}

.co_fv_card_container {
  border: 1px solid #cdced9;
  border-radius: 5px;
  margin-bottom: 12px;
}

.co_fv_card_content_container {
  padding: 10px;
  border-bottom: 1px solid #cdced9;
}

.df_align_c {
  display: flex;
  align-items: center;
  gap: 5px;
}

.--g1 {
  gap: 10px;
}

.co_fv_dtl_container {
  max-width: 375px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: "nowrap";
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

//revenue billing filter
.revenue_billing-download_button {
  display: flex;
  align-items: center;
  gap: 11px;
  padding: 13px;
  border-radius: 5px;
  background-color: #e4f0fc;
}

.revenue-check-filters-main {
  background-color: #ffffff;
  height: 50px;
  display: flex;
  gap: 15px;
  align-items: center;
  padding: 0px 8px;
}

.revenue-fiters-field {
  height: 36px;
  background: #dde3ff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}

.revenue-filter-icon_seprator {
  display: flex;
  gap: 6px;
  align-items: center;
}

.review-drawer_header {
  display: flex;
  gap: 5px;
  padding-bottom: 25px;
  align-items: center;
}

.payment_card_con_popver {
  background: #f0f5ff;
  padding: 2px;
  width: fit-content;
}

//MainTain Mapping Adhoc
.maintain-adhoc-main-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.email_forward_action_wraper {
  position: absolute;
  top: 0px;
  right: 3px;
  display: flex;
  gap: 5px;
  // background: #ffffff;
}

.email_forward_action_wraper p {
  padding: 4px;
  background: #ffffff;
  margin-top: 3px;
  cursor: pointer;
}

.client_hyperlink p {
  color: #516beb;
  text-decoration: underline;
  cursor: pointer;
}
#invoice-status-comment-editor .ql-container.ql-snow {
  border: none !important;
}
#invoice-status-comment-editor .ql-mention-list-container {
  height: 250px;
  overflow: auto;
}
