.content-container-status-maintenance {
  display: flex;
  // width: calc(100% - 60px);
  height: 100vh;
  flex-direction: column;
  padding: 25px 25px 0px 25px;
  margin-left: 60px;
}

.status-maintanenace-table-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #cdced9;
  border-radius: 5px;
  border-style: solid;
  overflow: hidden;
  // border-radius: 0px 5px 5px 0px;
  box-shadow: rgba(51, 56, 96, 0.1450980392) 1px 2px;
  height: 88vh;
  margin-top: 20px;
}

.report-maintanenace-table-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #cdced9;
  border-radius: 5px;
  border-style: solid;
  overflow: hidden;
  // border-radius: 0px 5px 5px 0px;
  box-shadow: rgba(51, 56, 96, 0.1450980392) 1px 2px;
  // height: 88vh;
  // margin-top: 20px;
}

.table-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.add-status-container {
  position: absolute;
  top: 3%;
  right: 2%;
}

.add-status-btn {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #e4f0fc;
  cursor: pointer;
}

.status-maintanenace-table {
  overflow: auto;
  max-height: calc(100vh - 190px);
}

.status-maintanenace-table table {
  width: 100% !important;
}

// table Head

.status-maintanenace-table table thead tr {
  background-color: #fafafa;
}

.status-maintanenace-table table thead tr th {
  text-align: center;
  border-right: 1px solid #cdced9;
  border-bottom: 1px solid #cdced9;
}

.status-maintanenace-table table tbody tr td {
  text-align: center;
  border-right: 1px solid #cdced9;
  border-bottom: 1px solid #cdced9;
}

.status-maintanenace-table table thead tr th:first-child {
  text-align: left;
  padding-left: 12px;
  width: 10%;
}

.status-maintanenace-table table thead tr th:nth-child(4) {
  width: 12%;
}

.status-maintanenace-table table thead tr th:nth-child(8) {
  width: 10%;
}

.status-maintanenace-table table thead tr th:nth-child(2) {
  width: 8%;
  padding: 1px;
}

.status-maintanenace-table table thead tr th:nth-child(3) {
  width: 8%;
}

.status-maintanenace-table table thead tr th:nth-child(3) {
  width: 8%;
}

.status-maintanenace-table table thead tr th:last-child {
  border-right: none;
  text-align: left;
}

//TableBody

.status-maintanenace-table table tbody tr td:first-child {
  width: 16%;
  text-align: left;
  padding-left: 12px;
}

.status-maintanenace-table table tbody tr td:nth-child(2) {
  width: 7%;
}

.status-maintanenace-table table tbody tr td:nth-child(3) {
  width: 10%;
}

.status-maintanenace-table table tbody tr td:nth-child(8) {
  width: 10%;
}

.status-maintanenace-table table tbody tr td:nth-child(10) {
  width: 12%;
}

.status-maintanenace-table table tbody tr td:last-child {
  width: 5%;
}

.status-maintanenace-table table tbody tr td:last-child {
  border-right: none;
}

.status-maintenance-table-last-section {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 10px 25px 10px 10px;
  background-color: #fafafa;
  border-top: 1px solid #cdced9;
  border-bottom-right-radius: "5px";
}

.check_sm_option {
  position: absolute;
  z-index: 1;
  right: 0%;
  top: 0%;
}

.--batch_options {
  position: relative;
}

.--bach_container {
  width: 750px;
}

.--bach_head_wraper {
  justify-content: start;
  align-items: center;
  gap: 10px;
}

//State Disclosure

.state-disclosure-table-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #cdced9;
  border-radius: 5px;
  border-style: solid;
  overflow: hidden;
  // border-radius: 0px 5px 5px 0px;
  // box-shadow: rgba(51, 56, 96, 0.1450980392) 1px 2px;
  // height: 88vh;
  // margin-top: 20px;
}

.state-disclosure-main-table table {
  width: 100% !important;
}

// table Head

.state-disclosure-main-table table thead tr {
  background-color: #fafafa;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

.state-disclosure-main-table table thead tr th {
  text-align: center;
  padding: 12px;
  // border-right: 1px solid c;
  // border-bottom: 1px solid #cdced9;
}

.state-disclosure-main-table table tbody tr td {
  text-align: center;

  // border-right: 1px solid #cdced9;
  // border-bottom: 1px solid #cdced9;
}

.state-disclosure-main-table table thead tr th:first-child {
  text-align: center;
  width: 6%;
}

.state-disclosure-main-table table thead tr th:nth-child(4) {
  width: 48%;
  text-align: start;
}

.state-disclosure-main-table table thead tr th:nth-child(2) {
  width: 18%;
  padding: 1px;
}

.state-disclosure-main-table table thead tr th:nth-child(3) {
  width: 18%;
}

.state-disclosure-main-table table thead tr th:last-child {
  border-right: none;
  width: 8%;
  text-align: center;
}

// .state-disclosure-main-table table tbody tr {
//   height: 10px !important;
// }

.status-maintanenace-table table tbody tr {
  height: 10px !important;
}

//TableBody

.status-maintanenace-table table tbody tr td:first-child {
  width: 16%;
  text-align: left;
  padding-left: 12px;
}

.status-maintanenace-table table tbody tr td:nth-child(2) {
  width: 7%;
}

.status-maintanenace-table table tbody tr td:nth-child(3) {
  width: 10%;
}

.status-maintanenace-table table tbody tr td:nth-child(8) {
  width: 10%;
}

.status-maintanenace-table table tbody tr td:nth-child(10) {
  width: 12%;
}

.status-maintanenace-table table tbody tr td:last-child {
  width: 5%;
}

.status-maintanenace-table table tbody tr td:last-child {
  border-right: none;
}

.state-disclosure-main-table {
  width: 100% !important;
  height: auto;
}

.state-disclosure-main-table table tbody tr td {
  text-align: center;
  border-right: 1px solid #cdced9;
  border-bottom: 1px solid #cdced9;
  border-left: 1px solid #cdced9;
}

.state-disclosure-main-table table thead tr th {
  text-align: center;
  border-right: 1px solid #cdced9;
  border-bottom: 1px solid #cdced9;
  border-left: 1px solid #cdced9;
  border-top: 1px solid #cdced9;
}

.state-disclosure-main-table {
  overflow: auto;
  max-height: calc(100vh - 240px);
}

.status-audit-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
}

.status-audit-table thead {
  position: sticky;
  top: 0;
  background-color: #f5f6fa;
  z-index: 10;
  display: table;
  width: 100%;
}

.status-audit-table th {
  background-color: #f5f6fa;
  text-align: left;
  padding: 5px;
  font-weight: 600;
  color: #333;
  max-width: 400px;
}

.status-audit-table tbody {
  display: block;
  max-height: 600px;
  /* Set height for scrolling */
  overflow-y: auto;
  width: 100%;
}

.status-audit-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  /* Ensures column widths are consistent */
}

.status-audit-table td {
  // padding: 15px 10px;
  border-bottom: 1px solid #e0e0e0;
  text-align: left;
}

.status-audit-table td:first-child,
.status-audit-table th:first-child {
  width: 250px;
}

.status-audit-table td:nth-child(2),
.status-audit-table th:nth-child(2) {
  width: 125px;
}

.status-audit-table td:last-child,
.status-audit-table th:last-child {
  width: 25px;
}

.status-audit-table tr:last-child td {
  border-bottom: none;
}